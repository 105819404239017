import React, { useEffect, useState, useRef } from "react"
import Button from "react-bootstrap/Button"
import { OverlayTrigger, Tooltip, Dropdown, DropdownButton, ButtonGroup, Form, InputGroup, Tab, Tabs } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExport, faFileImport, faClose } from "@fortawesome/free-solid-svg-icons"

import "./Homepage.less"
import { useConfig } from "../../contexts/configContext"
import { useSnackbar } from "../../contexts/snackbarContext"
import { isNonEmptyArray, isNonEmptyNumber, isNonEmptyObject, isNonEmptyString } from "../../utils"
import useHttpApi from "../useHttpApi"
import axios from "axios"
import CrossedInput from "../SharedComponents/CrossedInput"
import ConfirmModal from "../UI/ConfirmModal/ConfirmModal"
import _ from "lodash"
import { useLogin } from "../../contexts/loginContext"
import { useNavigate } from "react-router-dom"

function Homepage(props) {
  const [cAdministrativeState, setcAdministrativeState] = useState(null)
  const [pAdministrativeState, setpAdministrativeState] = useState(null)
  const [producer, setproducer] = useState(null)
  const [originalProducer, setoriginalProducer] = useState(null)
  const [consumer, setconsumer] = useState(null)
  const [originalConsumer, setoriginalConsumer] = useState(null)
  const [doublePostMOIAttribute, setdoublePostMOIAttribute] = useState(false)
  const [scpisAvailable, setscpisAvailable] = useState(false)
  const [scpisEmbedded, setscpisEmbedded] = useState({ enabled: false })
  const [scp, setscp] = useState(null)
  const [nrfisAvailable, setnrfisAvailable] = useState(false)
  const [nrfButtonsDisabled, setnrfButtonsDisabled] = useState(true)
  const [nrf, setnrf] = useState(null)
  const [vasProducer, setvasProducer] = useState(null)
  const [uploadErrors, setuploadErrors] = useState([])
  const [isSaveDisabled, setisSaveDisabled] = useState(false)
  // const [ispvasDataPresent, setispvasDataPresent] = useState(false)
  // const [iscvasDataPresent, setiscvasDataPresent] = useState(false)
  const [openConfirmModal, setopenConfirmModal] = useState(false)
  const [nonMandaryData, setnonMandaryData] = useState(null)
  const [alarmTable, setalarmTable] = useState([])
  const [fetchedAlarmTable, setfetchedAlarmTable] = useState(null)
  const [vasIsAvailable, setvasIsAvailable] = useState(false)
  const [missingKeys, setmissingKeys] = useState([])
  const [beginningMissingKeys, setbeginningMissingKeys] = useState([])

  const config = useConfig()
  const snackbar = useSnackbar()
  const login = useLogin()
  const navigate = useNavigate()
  const cvasInputRef = useRef(null)
  const pvasInputRef = useRef(null)
  const namespace = config.namespace

  const psepp = "pSEPP"
  const csepp = "cSEPP"

  const admittedIpChars = /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/gm
  const admittedPortChars = /\d{1,4}/gm

  const httpApi = useHttpApi()

  useEffect(() => {
    const token = localStorage.getItem("Token")
    if (!token) {
      login.setisUserLogged(false)
    }
  }, [])

  const createRedisKey = (key, data) => {
    console.log(`creating ${key} key`)
    httpApi.callPostAPI({ url: key, data, snack: { show: false } })
  }
  //Keys creation, if needed

  const checkIfRedisKeyIsEmpty = (data) => {
    if (data == null) return -1
    if (data === "") return -1
    if (data.toString() === "[]") return -1
    if (data.toString() === "{}") return -1
    return "ok"
  }

  const getHomepageData = () => {
    return Promise.all([
      httpApi.callGetAPI({
        url: "json/MOIattributes",
        snack: { show: false },
        errorCallback: (err) => {
          if (err.response.status === 404) return "MOIattributes"
        },
        successCallback: (data) => {
          computeConsumerAndProducer(data)
          if (checkIfRedisKeyIsEmpty(data) === "ok") {
            return 1
          }
          return "MOIattributes"
        },
      }),
      // httpApi.callGetAPI({
      //   url: "json/cvas_rules",
      //   snack: { show: false },
      //   successCallback: (data) => {
      //     checkIfRedisKeyIsEmpty(data) === "ok" ? setiscvasDataPresent(true) : setiscvasDataPresent(false)
      //     return 1
      //   },
      // }),
      // httpApi.callGetAPI({
      //   url: "json/pvas_rules",
      //   snack: { show: false },
      //   successCallback: (data) => {
      //     checkIfRedisKeyIsEmpty(data) === "ok" ? setispvasDataPresent(true) : setispvasDataPresent(false)
      //     return 1
      //   },
      // }),
      httpApi.callGetAPI({
        url: "json/pvas_next_hop",
        snack: { show: false },
        successCallback: (data) => {
          if (checkIfRedisKeyIsEmpty(data) === "ok") setvasProducer(data)
          return 1
        },
      }),
      httpApi.callGetAPI({
        url: "json/nrf-configuration",
        snack: { show: false },
        errorCallback: (err) => {
          if (err.response.status === 404) return "nrf-configuration"
        },
        successCallback: (data) => {
          if (checkIfRedisKeyIsEmpty(data) === "ok") {
            const { BaseAddress, ..._data } = data
            const nonEmptyValues = Object.values(_data).find((el) => isNonEmptyString(el))

            nonEmptyValues ? setnrfisAvailable(true) : setnrfisAvailable(false)
            nonEmptyValues ? setnrfButtonsDisabled(false) : setnrfButtonsDisabled(true)
            setnrf(_data || null)
            return 1
          }
          return "nrf-configuration"
        },
      }),
      httpApi.callGetAPI({
        url: "json/scp-configuration",
        snack: { show: false },
        errorCallback: (err) => {
          if (err.response.status === 404) return "scp-configuration"
        },
        successCallback: (data) => {
          if (checkIfRedisKeyIsEmpty(data) === "ok") {
            const { BaseAddress, ..._data } = data
            isNonEmptyObject(_data) ? setscpisAvailable(true) : setscpisAvailable(false)
            setscp(_data || null)
            return 1
          }
          return "scp-configuration"
        },
      }),
      httpApi.callGetAPI({
        url: "json/scp-embedded",
        snack: { show: false },
        successCallback: (data) => {
          if (checkIfRedisKeyIsEmpty(data) === "ok") setscpisEmbedded(data)
          return 1
        },
      }),
      httpApi.callGetAPI({
        url: "features/RulesHandling",
        snack: { show: false },
        successCallback: (data) => {
          if (checkIfRedisKeyIsEmpty(data) === "ok") setvasIsAvailable(data)
          return 1
        },
      }),
    ])
  }

  //Getting the data and setting the states. Setting the missing redis keys and showing the "first entrance" popup if needed
  useEffect(() => {
    getHomepageData().then((data) => {
      const _missingKeys = data.filter((el) => el !== 1)
      if (_missingKeys.length > 0) {
        setmissingKeys(_missingKeys)
        setbeginningMissingKeys(_missingKeys)
      }
    })
  }, [])

  //Showing the "missing keys" warning popup
  useEffect(() => {
    if (beginningMissingKeys.length > 0) {
      if (missingKeys.includes("MOIattributes")) {
        snackbar.setSnackbarInfo({
          duration: 12000,
          status: "info",
          message:
            "Welcome! Is it your first time here? Please set all the fields below and press the <b>SAVE</b> button in order to configure your <i>SEPP</i>.",
        })
        setmissingKeys((prev) => prev.filter((el) => el !== "MOIattributes"))
      } else if (missingKeys.includes("scp-configuration")) {
        snackbar.setSnackbarInfo({
          duration: 8000,
          status: "warning",
          message: `No <b>SCP</b> was found for this <i>SEPP</i>.`,
        })
        setmissingKeys((prev) => prev.filter((el) => el !== "scp-configuration"))
      } else if (missingKeys.includes("nrf-configuration")) {
        snackbar.setSnackbarInfo({
          duration: 8000,
          status: "warning",
          message: `No <b>NRF</b> was found for this <i>SEPP</i>.`,
        })
        setmissingKeys((prev) => prev.filter((el) => el !== "nrf-configuration"))
      } else {
        setmissingKeys([])
        setbeginningMissingKeys([])
      }
    }
  }, [missingKeys])

  const getMOIAttributes = async () => {
    const res = await httpApi.callGetAPI({
      url: "json/MOIattributes",
      successCallback: (data) => data || [],
      errorCallback: (err) => -1,
      snack: {
        show: false,
      },
    })
    return res
  }

  useEffect(() => {
    //uncomment the following line to push the homepage data for the first time or when something changes inside the json, then press Save
    // publishData()
  }, [])

  const computeConsumerAndProducer = (_moiAttributes) => {
    const _producer = _moiAttributes.find((ma) => ma.sEPPType === psepp)
    const _consumer = _moiAttributes.find((ma) => ma.sEPPType === csepp)

    if (!_producer) {
      setproducer(buildProducer())
      setoriginalProducer(buildProducer())
    } else {
      setproducer(_producer)
      setoriginalProducer(_producer)
    }

    if (!_consumer) {
      setoriginalConsumer(buildConsumer())
      setconsumer(buildConsumer())
    } else {
      setoriginalConsumer(_consumer)
      setconsumer(_consumer)
    }
  }

  const publishData = () => {
    const firstTimeData = [
      {
        administrativeState: "LOCKED",
        operationalState: "DISABLED",
        fqdn: config.configInfo.seppCommonName,
        pLMNId: config.configInfo.plmnid,
        sEPPType: psepp,
        id: `${namespace}-${psepp}`,
        sEPPId: `2${namespace.split("-").find((el) => el.match(/\d+/gm))}`,
        objectClass: "SEPP",
        ipv4Addr: "4.4.4.4",
        port: "4444",
        href: window.location.href,
        security: ["TLS"],
      },
      {
        administrativeState: "LOCKED",
        operationalState: "DISABLED",
        fqdn: config.configInfo.seppCommonName,
        pLMNId: config.configInfo.plmnid,
        sEPPType: csepp,
        id: `${namespace}-${csepp}`,
        sEPPId: `1${namespace.split("-").find((el) => el.match(/\d+/gm))}`,
        objectClass: "SEPP",
        ipv4Addr: "2.2.2.2",
        port: "2222",
        href: window.location.href,
        security: ["TLS"],
      },
    ]

    httpApi.callPostAPI({
      url: "json/MOIattributes",
      data: firstTimeData,
      snack: {
        show: true,
        success: {
          status: "success",
          message: "Data correctly saved",
        },
      },
    })
  }

  //if both the Producer and the Consumer were modified, we need to split the MOIattribute saving in two different operation:
  //- The first one is done in saveHomepageData (for the consumer with the original value of the producer)
  //- The second one here (for the producer with the new value of the consumer)
  useEffect(() => {
    if (doublePostMOIAttribute) {
      const _producer = buildProducer()
      const _consumer = buildConsumer()
      saveMoiAttributes(_producer, _consumer)
      setdoublePostMOIAttribute(false)
    }
  }, [doublePostMOIAttribute])

  const saveHomepageData = async () => {
    const _producer = buildProducer()
    const _consumer = buildConsumer()

    let promises = []
    if (!_.isEqual(_producer, originalProducer) && !_.isEqual(_consumer, originalConsumer))
      promises = await Promise.all([
        saveMoiAttributes(originalProducer, _consumer, false),
        saveScpData(),
        saveScpEmbeddedData(),
        saveNrfData(),
        savePVASNextHop(),
      ])
    else
      promises = await Promise.all([
        saveMoiAttributes(_producer, _consumer),
        saveScpData(),
        saveScpEmbeddedData(),
        saveNrfData(),
        savePVASNextHop(),
      ])

    //We need to do separate api call for posting producer and consumer to the MOIattribute
    if (!_.isEqual(_producer, originalProducer) && !_.isEqual(_consumer, originalConsumer)) setdoublePostMOIAttribute(true)
    const errors = promises.filter((el) => !el.status).map((el) => el.name)
    const valids = promises.filter((el) => el.status).map((el) => el.name)

    if (errors.length > 0) {
      snackbar.setSnackbarInfo({
        status: "error",
        message: `An error occurred while saving ${errors.join(", ")} keys. Please try again or contact the administartor.`,
      })
    }
    if (valids.length > 0) {
      snackbar.setSnackbarInfo({ status: "success", message: `Data saved successfully!` })
    }
  }

  const saveMoiAttributes = async (_producer, _consumer, doGet = true) => {
    return await httpApi.callPostAPI({
      url: "json/MOIattributes",
      data: [_producer, _consumer],
      snack: {
        show: false,
        success: {
          status: "success",
          message: "Data successfully saved!",
        },
      },
      successCallback: () => {
        if (doGet) {
          getMOIAttributes().then((_moiAttributes) => {
            computeConsumerAndProducer(_moiAttributes)
          })
        }
        return { name: "moiAttributes", status: true }
      },

      errorCallback: () => ({ name: "moiAttributes", status: false }),
    })
  }

  const savePVASNextHop = async () => {
    return await httpApi.callPostAPI({
      url: "json/pvas_next_hop",
      snack: { show: false },
      data: vasProducer || {},
      successCallback: () => ({ name: "vas", status: true }),
      errorCallback: () => ({ name: "vas", status: false }),
    })
  }

  const saveNrfData = async () => {
    if (!nrf) return { name: "nrf", status: false }
    nrf.BaseAddress = isNonEmptyString(nrf.url) ? nrf.url : `${nrf.ipv4Addr || ""}:${nrf.port || ""}`
    nrf.BaseAddress === ":" && (nrf.BaseAddress = "")

    return await httpApi.callPostAPI({
      url: "json/nrf-configuration",
      data: nrf || {},
      snack: { show: false },
      successCallback: async () => {
        await httpApi.callGetAPI({ url: "json/nrf-configuration", snack: { show: false } }).then((data) => {
          setnrf(data)
          if ((isNonEmptyObject(data) && isNonEmptyString(data.url)) || (isNonEmptyString(data.ipv4Addr) && isNonEmptyString(data.port)))
            setnrfButtonsDisabled(false)
          else setnrfButtonsDisabled(true)
        })
        return { name: "nrf", status: true }
      },
      errorCallback: () => ({ name: "nrf", status: false }),
    })
  }

  const saveScpData = async () => {
    if (!scp) return { name: "scp", status: false }
    scp.BaseAddress = isNonEmptyString(scp.url) ? scp.url : `${scp.ipv4Addr || ""}:${scp.port || ""}`
    scp.BaseAddress === ":" && (scp.BaseAddress = "")

    return await httpApi.callPostAPI({
      url: "json/scp-configuration",
      data: scp || {},
      snack: { show: false },
      successCallback: async () => {
        await httpApi.callGetAPI({ url: "json/scp-configuration", snack: { show: false } }).then((data) => setscp(data))
        return { name: "scp", status: true }
      },
      errorCallback: () => ({ name: "scp", status: false }),
    })
  }

  const saveScpEmbeddedData = async () => {
    console.log(scpisEmbedded)
    return await httpApi.callPostAPI({
      url: "json/embedded-scp",
      data: scpisEmbedded,
      snack: { show: false },
      successCallback: async () => {
        await httpApi.callGetAPI({ url: "json/embedded-scp", snack: { show: false } }).then((data) => setscpisEmbedded(data))
        return { name: "embedded-scp", status: true }
      },
      errorCallback: () => ({ name: "embedded-scp", status: false }),
    })
  }

  const buildProducer = () => {
    return {
      administrativeState: pAdministrativeState || producer.administrativeState || "LOCKED",
      operationalState: producer.operationalState || "DISABLED",
      fqdn: config.configInfo.seppCommonName,
      pLMNId: config.configInfo.plmnid,
      sEPPType: psepp,
      id: `${namespace}-${psepp}`,
      sEPPId: `2${namespace.split("-").find((el) => el.match(/\d+/gm))}`,
      objectClass: "SEPP",
      ipv4Addr: producer.ipv4Addr || "",
      port: producer.port || "",
      href: window.location.href,
      security: producer.security || ["TLS"],
    }
  }

  const buildConsumer = () => {
    return {
      administrativeState: cAdministrativeState || consumer.administrativeState || "LOCKED",
      operationalState: consumer.operationalState || "DISABLED",
      fqdn: config.configInfo.seppCommonName,
      pLMNId: config.configInfo.plmnid,
      sEPPType: csepp,
      id: `${namespace}-${csepp}`,
      sEPPId: `1${namespace.split("-").find((el) => el.match(/\d+/gm))}`,
      objectClass: "SEPP",
      ipv4Addr: consumer.ipv4Addr || "",
      port: consumer.port || "",
      href: window.location.href,
      security: consumer.security || ["TLS"],
    }
  }

  const checkSecurity = (e, prosumer, value) => {
    let tmp = { ...prosumer }
    if (!tmp.security) tmp.security = []
    if (e.target.checked && !tmp.security.includes(value)) tmp.security.push(value)
    else tmp.security = tmp.security.filter((item) => item !== value)
    return tmp
  }

  const importFile = (vasRef) => {
    vasRef.current.click()
  }

  const handleFileUploadEvent = (e, vasType) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = (e) => uploadVasFile(e.target.result, vasType, file)
      e.target.value = ""
    }
  }

  const uploadVasFile = async (fileContent, vasType, file) => {
    const errors = new Set(uploadErrors)
    try {
      fileContent = JSON.parse(fileContent)
    } catch (err) {
      errors.add(`This file is not in JSON format. Please fix it and retry.`)
      setuploadErrors(Array.from(errors))
      return
    }

    let formData = new FormData()
    formData.append("file", file)

    await httpApi.callUploadAPI({
      url: `${vasType === "cvas" ? "file/cvas_rules" : "file/pvas_rules"}`,
      formData,
      snack: {
        show: true,
        success: {
          status: "success",
          message: `Your file has successfully been uploaded`,
        },
      },
      // successCallback: () => {
      //   if (vasType === "cvas") {
      //     isNonEmptyObject(fileContent) ? setiscvasDataPresent(true) : setiscvasDataPresent(false)
      //   }
      //   if (vasType === "pvas") {
      //     isNonEmptyObject(fileContent) ? setispvasDataPresent(true) : setispvasDataPresent(false)
      //   }
      // },
    })
  }

  const downloadVasFile = async (vasType) => {
    const fileName = `${vasType === "cvas" ? "cvas_rules" : "pvas_rules"}`
    const url = `${vasType === "cvas" ? "file/cvas_rules" : "file/pvas_rules"}`
    await httpApi.callDownloadAPI(
      {
        url,
        snack: {
          show: true,
          success: {
            status: "success",
            message: "Vas file successfully exported",
          },
        },
      },
      { name: fileName, type: "json" }
    )
  }

  const discovery = async () => {
    await axios({
      method: "post",
      url: "discover",
      baseURL: window.location.origin,
    })
      .then((data) => {
        snackbar.setSnackbarInfo({
          status: "success",
          message: "Discovery correctly sent!",
        })
      })
      .catch((err) => {
        snackbar.setSnackbarInfo({ status: "error", message: `Error: ${err}` })
      })
  }

  const register = async () => {
    //se due click vengono fatti in un intervallo di 10 secondi, ottengo un errore "too many request"
    await axios({
      method: "post",
      url: "register",
      baseURL: window.location.origin,
    })
      .then((data) => {
        snackbar.setSnackbarInfo({
          status: "success",
          message: "Register correctly sent!",
        })
      })
      .catch((err) => {
        snackbar.setSnackbarInfo({ status: "error", message: `Error: ${err}` })
      })
  }

  const handleInput = (e, obj, field) => {
    if (obj === "producer") {
      const _producer = { ...producer }
      _producer[field] = e.target.value
      setproducer(_producer)
    }
    if (obj === "consumer") {
      const _consumer = { ...consumer }
      _consumer[field] = e.target.value
      setconsumer(_consumer)
    }
    if (obj === "vasProducer") {
      const _vasProducer = { ...vasProducer }
      _vasProducer[field] = e.target.value
      setvasProducer(_vasProducer)
    }
    if (obj === "scp") {
      const _scp = { ...scp }
      if (field === "ipv4Addr") {
        const test = e.target.value.match(/\d|\./g)
        _scp[field] = test != null && test.length > 0 ? test.join("") : null
        _scp.url = ""
      }
      if (field === "port") {
        const test = e.target.value.match(/\d/g)
        _scp[field] = test != null && test.length > 0 ? test.join("") : null
        _scp.url = ""
      }
      if (field === "url") {
        _scp.url = e.target.value
        _scp.ipv4Addr = ""
        _scp.port = ""
      }
      setscp(_scp)
    }
    if (obj === "nrf") {
      const _nrf = { ...nrf }
      if (field === "ipv4Addr") {
        const test = e.target.value.match(/\d|\./g)
        _nrf[field] = test != null && test.length > 0 ? test.join("") : null
        _nrf.url = ""
      }
      if (field === "port") {
        const test = e.target.value.match(/\d/g)
        _nrf[field] = test != null && test.length > 0 ? test.join("") : null
        _nrf.url = ""
      } else {
        _nrf.url = e.target.value
        _nrf.ipv4Addr = ""
        _nrf.port = ""
      }
      setnrf(_nrf)
    }
  }

  useEffect(() => {
    checkRequiredFields()
  }, [consumer, producer])

  const checkRequiredFields = () => {
    setisSaveDisabled(
      !isNonEmptyString(consumer?.ipv4Addr) ||
        !isNonEmptyString(consumer?.port) ||
        !isNonEmptyString(producer?.ipv4Addr) ||
        !isNonEmptyString(producer?.port)
    )
  }

  const checkNonMandaryData = () => {
    const data = [
      // ispvasDataPresent && vasIsAvailable ? null : "Producer VAS rules",
      // iscvasDataPresent && vasIsAvailable ? null : "Consumer VAS rules",
      scpisAvailable || scpisEmbedded.enabled ? null : "SCP configuration",
      nrfisAvailable ? null : "NRF configuration",
    ].filter((el) => el)

    if (data.length === 0) {
      setnonMandaryData(null)
      saveHomepageData()
    } else {
      setnonMandaryData(data)
      setopenConfirmModal(true)
    }
  }

  useEffect(() => {
    getAlarmTable().then((res) => {
      if (res !== -1) setfetchedAlarmTable(res)
    })
  }, [])

  const getAlarmTable = async () => {
    const data = await httpApi.callGetAPI({
      url: `json/${config.configInfo.protectionGroupID}alarm-table`,
      snack: {
        show: false,
        info: { status: "info", message: `There are no alarm data to show.` },
      },
    })
    return data || -1
  }

  const buildAlarmTable = (fetchedAlarmTable) => {
    const _alarmTable = new Set()
    //getting the mib object
    Object.entries(fetchedAlarmTable).map((k, v) => {
      const mib = k[1]
      //extracting the mib id and the alarm object
      return Object.entries(mib).map((k1, v1) => {
        const alarmObject = k1[1]
        //build the alarm object by checking the mib id on the mibTable (in the config file)
        return alarmObject.map((alarm) => {
          if (alarm.model) {
            alarm.model.involvedCounterpart = alarm.model.additionalInformation.counterPart

            // alarm.model.notificationType!=='notifyClearedAlarm' && _alarmTable.add(alarm.model)
            _alarmTable.add(alarm.model)
          }
          return alarm
        })
      })
    })

    return Array.from(_alarmTable)
  }

  const filterAlarmTable = (alarms) => {
    const specificProblemFilter = ["NRF_NOT_REACHABLE", "SCP COMMUNICATION TIMEOUT"]
    const filteredAlarms = alarms.filter((el) => specificProblemFilter.includes(el.specificProblem))
    return filteredAlarms
  }

  useEffect(() => {
    if (fetchedAlarmTable) {
      try {
        const _alarmTable = buildAlarmTable(fetchedAlarmTable)
        const filteredAlarmTable = filterAlarmTable(_alarmTable)
        setalarmTable(filteredAlarmTable)
      } catch (error) {
        snackbar.setSnackbarInfo({
          status: "error",
          message: `<b>An error occurred:</b> ${error.toString().replace("Error:", "")}`,
        })
      }
    }
  }, [config.namespace, fetchedAlarmTable])

  return (
    <>
      <ConfirmModal
        bsSize="medium"
        show={openConfirmModal}
        onHide={() => {
          setnonMandaryData(null)
          setopenConfirmModal(false)
        }}
        onClose={() => {
          setnonMandaryData(null)
          setopenConfirmModal(false)
        }}
        title="Warning!"
        className="text-center"
        onAccept={(e) => {
          setopenConfirmModal(false)
          saveHomepageData()
        }}
        closeText="Close"
      >
        <div style={{ textAlign: "left" }}>
          <p>The following data have not been set yet:</p>
          {isNonEmptyArray(nonMandaryData) &&
            nonMandaryData.map((el) => (
              <p
                style={{
                  fontSize: "10pt",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
              >{` - ${el}`}</p>
            ))}
          <p>Do you want to continue anyway?</p>
        </div>
      </ConfirmModal>

      <Form>
        <div className="page">
          <Tabs defaultActiveKey="Details" id="seep-tabs" className="mb-3" justify>
            <Tab eventKey="Details" title="DETAILS">
              <h5 className="title mt-5">Sepp Details</h5>
              <div className="data-row">
                <span>Sepp ID:</span>
                <span
                  style={{
                    fontSize: "11pt",
                    ...(namespace === "ERROR" ? { color: "red" } : namespace === "NOT SET" ? { color: "orange" } : {}),
                  }}
                >
                  {namespace}
                </span>
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>PLMNID:</span>
                <span
                  style={{
                    ...(namespace === "ERROR" ? { color: "red" } : namespace === "NOT SET" ? { color: "orange" } : {}),
                  }}
                >
                  {`MCC:${config.configInfo.plmnid.split("-")[0]} MNC:${config.configInfo.plmnid.split("-")[1]}`}
                </span>
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Domain:</span>
                <span style={config.configInfo.dnsName == null || config.configInfo.dnsName === "" ? { color: "orange" } : {}}>
                  {config.configInfo.dnsName || "NOT SET"}
                </span>
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Monitoring Component:</span>
                <span style={isNonEmptyString(config.configInfo.monitoringpath) ? {} : { color: "orange" }}>
                  {config.configInfo.monitoringpath || "NOT SET"}
                </span>
              </div>
              <div className="separator"></div>
            </Tab>

            <Tab
              eventKey="Consumer"
              title={
                <div className="rowDiv j-center">
                  <span>CONSUMER</span>
                  {(!isNonEmptyString(consumer?.ipv4Addr) || !isNonEmptyString(consumer?.port)) && <span className="mandatory-tab"></span>}
                </div>
              }
            >
              <h5 className="title mt-5">Consumer Side Details</h5>
              <div className="data-row">
                <div>
                  <span>IP Address:</span>
                  <span className="mandatory-field"> *</span>
                </div>
                <CrossedInput
                  size="40%"
                  object={consumer}
                  value={consumer?.ipv4Addr || ""}
                  placeholder="192.168.1.1"
                  callback={(e) => handleInput(e, "consumer", "ipv4Addr")}
                />
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <div>
                  <span>Port:</span>
                  <span className="mandatory-field"> *</span>
                </div>
                <CrossedInput
                  size="40%"
                  object={consumer}
                  value={consumer?.port || ""}
                  placeholder="1111"
                  callback={(e) => handleInput(e, "consumer", "port")}
                />
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Administrative State:</span>
                <DropdownButton
                  as={ButtonGroup}
                  key="primary"
                  id={`dropdown-variants-primary`}
                  title={cAdministrativeState || consumer?.administrativeState || "Empty"}
                  onSelect={(value) => setcAdministrativeState(value)}
                >
                  <Dropdown.Item active={cAdministrativeState === "LOCKED"} eventKey="LOCKED">
                    LOCKED
                  </Dropdown.Item>
                  <Dropdown.Item active={cAdministrativeState === "UNLOCKED"} eventKey="UNLOCKED">
                    UNLOCKED
                  </Dropdown.Item>
                </DropdownButton>
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Operational State:</span>
                <span style={consumer?.operationalState != null && consumer?.operationalState !== "" ? {} : { color: "orange" }}>
                  {consumer?.operationalState != null && consumer?.operationalState !== "" ? consumer.operationalState : "NOT SET"}
                </span>
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Security Capabilities:</span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Form.Check
                    inline
                    type="checkbox"
                    id="TLS-C"
                    label="TLS"
                    checked={consumer?.security?.includes("TLS") || false}
                    disabled
                    onChange={(e) => {
                      setconsumer(checkSecurity(e, consumer, "TLS"))
                    }}
                  ></Form.Check>
                  <Form.Check
                    style={{ marginRight: "0px" }}
                    inline
                    type="checkbox"
                    id="PRINS-C"
                    label="PRINS"
                    checked={consumer?.security?.includes("PRINS") || false}
                    onChange={(e) => {
                      setconsumer(checkSecurity(e, consumer, "PRINS"))
                    }}
                    disabled
                  ></Form.Check>
                </div>
              </div>
              <div className="separator"></div>
            </Tab>

            <Tab
              eventKey="Producer"
              title={
                <div className="rowDiv j-center">
                  <span>PRODUCER</span>
                  {(!isNonEmptyString(producer?.ipv4Addr) || !isNonEmptyString(producer?.port)) && <span className="mandatory-tab"></span>}
                </div>
              }
            >
              <h5 className="title mt-5">Producer Side Details</h5>
              <div className="data-row">
                <div>
                  <span>IP Address:</span>
                  <span className="mandatory-field"> *</span>
                </div>
                <CrossedInput
                  size="40%"
                  object={producer}
                  value={producer?.ipv4Addr || ""}
                  placeholder="192.168.1.1"
                  callback={(e) => handleInput(e, "producer", "ipv4Addr")}
                />
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <div>
                  <span>Port:</span>
                  <span className="mandatory-field"> *</span>
                </div>
                <CrossedInput
                  size="40%"
                  object={producer}
                  value={producer?.port || ""}
                  placeholder="1111"
                  callback={(e) => handleInput(e, "producer", "port")}
                />
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Administrative State:</span>
                <DropdownButton
                  as={ButtonGroup}
                  key="primary"
                  id={`dropdown-variants-primary`}
                  variant="primary"
                  title={pAdministrativeState || producer?.administrativeState || "Empty"}
                  onSelect={(value) => setpAdministrativeState(value)}
                >
                  <Dropdown.Item active={pAdministrativeState === "LOCKED"} eventKey="LOCKED">
                    LOCKED
                  </Dropdown.Item>
                  <Dropdown.Item active={pAdministrativeState === "UNLOCKED"} eventKey="UNLOCKED">
                    UNLOCKED
                  </Dropdown.Item>
                </DropdownButton>
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Operational State:</span>
                <span style={producer?.operationalState != null && producer?.operationalState !== "" ? {} : { color: "orange" }}>
                  {producer?.operationalState != null && producer?.operationalState !== "" ? producer.operationalState : "NOT SET"}
                </span>
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>Security Capabilities:</span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Form.Check
                    inline
                    type="checkbox"
                    id="TLS-C"
                    label="TLS"
                    checked={producer?.security?.includes("TLS") || false}
                    disabled
                    onChange={(e) => {
                      setproducer(checkSecurity(e, producer, "TLS"))
                    }}
                  ></Form.Check>
                  <Form.Check
                    style={{ marginRight: "0px" }}
                    inline
                    type="checkbox"
                    id="PRINS-C"
                    label="PRINS"
                    checked={producer?.security?.includes("PRINS") || false}
                    onChange={(e) => {
                      setproducer(checkSecurity(e, producer, "PRINS"))
                    }}
                    disabled
                  ></Form.Check>
                </div>
              </div>
              <div className="separator"></div>
            </Tab>

            {vasIsAvailable && (
              <Tab eventKey="vas-rules" title="VAS RULES">
                <h5 className="title mt-5">VAS Rules</h5>
                <div className="data-row">
                  <span>Consumer VAS rules</span>
                  <div className="rowDiv j-start">
                    <div
                      onClick={() => importFile(cvasInputRef)}
                      style={{
                        padding: "5px 10px",
                        cursor: "pointer",
                      }}
                    >
                      <span style={{ marginRight: "5px" }}>Upload</span>
                      <input
                        ref={cvasInputRef}
                        type="file"
                        accept="application/JSON"
                        id="cvasUpload"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleFileUploadEvent(e, "cvas")
                        }}
                      />
                      <OverlayTrigger placement="top" overlay={<Tooltip style={{ position: "absolute" }}>Import CVAS file</Tooltip>}>
                        <FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon>
                      </OverlayTrigger>
                    </div>
                    <div
                      onClick={() => {
                        downloadVasFile("cvas")
                      }}
                      style={{
                        padding: "5px 10px",
                        cursor: "pointer",
                      }}
                    >
                      <span style={{ marginRight: "10px" }}>Download</span>
                      <OverlayTrigger placement="top" overlay={<Tooltip style={{ position: "absolute" }}>Export CVAS file</Tooltip>}>
                        <FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div className="separator"></div>

                <div className="data-row">
                  <span>Producer VAS rules</span>
                  <div className="rowDiv j-start">
                    <div
                      onClick={() => importFile(pvasInputRef)}
                      style={{
                        padding: "5px 10px",
                        cursor: "pointer",
                      }}
                    >
                      <span style={{ marginRight: "5px" }}>Upload</span>
                      <input
                        ref={pvasInputRef}
                        type="file"
                        accept="application/JSON"
                        id="cvasUpload"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleFileUploadEvent(e, "pvas")
                        }}
                      />
                      <OverlayTrigger placement="top" overlay={<Tooltip style={{ position: "absolute" }}>Import PVAS file</Tooltip>}>
                        <FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon>
                      </OverlayTrigger>
                    </div>
                    <div
                      onClick={() => {
                        downloadVasFile("pvas")
                      }}
                      style={{
                        padding: "5px 10px",
                        cursor: "pointer",
                      }}
                    >
                      <span style={{ marginRight: "10px" }}>Download</span>
                      <OverlayTrigger placement="top" overlay={<Tooltip style={{ position: "absolute" }}>Export PVAS file</Tooltip>}>
                        <FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div className="separator"></div>

                {/* <div className="data-row">
              <span>IP Address:</span>
              <CrossedInput
                size="40%"
                object={vasProducer}
                value={vasProducer?.ipv4Addr || ""}
                placeholder="192.168.1.1"
                callback={(e) => handleInput(e, "vasProducer", "ipv4Addr")}
              />
            </div>
            <div className="separator"></div>

            <div className="data-row">
              <span>Port:</span>
              <CrossedInput
                size="40%"
                object={vasProducer}
                value={vasProducer?.port || ""}
                placeholder="1111"
                callback={(e) => handleInput(e, "vasProducer", "port")}
              />
            </div>
            <div className="separator"></div> */}
                {uploadErrors.length > 0 && (
                  <div className="upload-errors mt-5 mb-5">
                    <span className="errors-title">Some errors have been found on your file!</span>
                    {uploadErrors.map((err) => (
                      <div>- {err}.</div>
                    ))}
                  </div>
                )}
              </Tab>
            )}

            <Tab
              eventKey="scp-configuration"
              title={
                <div className="rowDiv j-center">
                  {alarmTable.find((el) => el.specificProblem === "SCP COMMUNICATION TIMEOUT") && (
                    <OverlayTrigger overlay={<Tooltip style={{ position: "absolute" }}>SCP COMMUNICATION TIMEOUT</Tooltip>}>
                      <span className="attention">!</span>
                    </OverlayTrigger>
                  )}
                  <span
                    style={
                      alarmTable.find((el) => el.specificProblem === "SCP COMMUNICATION TIMEOUT") ? { borderBottom: "solid 1px red" } : {}
                    }
                  >
                    SCP CONFIGURATION
                  </span>
                </div>
              }
            >
              <h5 className="title mt-5">SCP Configuration</h5>
              <div className="data-row">
                <span>Is Embedded</span>
                <div className="rowDiv j-around">
                  <span style={{ marginTop: "8px", marginRight: "5px" }}>NO</span>
                  <Form.Check
                    type="switch"
                    checked={scpisEmbedded?.enabled || false}
                    onChange={(e) => setscpisEmbedded({ enabled: e.target.checked })}
                  />
                  <span style={{ marginTop: "8px", marginLeft: "5px" }}>YES</span>
                </div>
              </div>
              <div className="separator"></div>

              <div className="data-row">
                <span>
                  {alarmTable.find((el) => el.specificProblem === "SCP COMMUNICATION TIMEOUT") && (
                    <OverlayTrigger overlay={<Tooltip style={{ position: "absolute" }}>SCP COMMUNICATION TIMEOUT</Tooltip>}>
                      <span className="attention">!</span>
                    </OverlayTrigger>
                  )}
                  Is Available
                </span>
                <div className="rowDiv j-around">
                  <span style={{ marginTop: "8px", marginRight: "5px" }}>NO</span>
                  <Form.Check
                    style={{ marginTop: "7px" }}
                    type="switch"
                    checked={scpisAvailable || false}
                    onChange={(e) => {
                      if (!e.target.checked) setscp({})
                      setscpisAvailable(e.target.checked)
                    }}
                  />
                  <span style={{ marginTop: "8px", marginLeft: "5px" }}>YES</span>
                </div>
              </div>
              <div className="separator"></div>

              {scpisAvailable && (
                <>
                  <div className="data-row" style={{ marginLeft: "10%" }}>
                    <span>IP Address</span>
                    <CrossedInput
                      size="40%"
                      object={scp}
                      value={scp?.ipv4Addr || ""}
                      placeholder="192.168.1.1"
                      clearDisabled={isNonEmptyString(scp?.url)}
                      inputDisabled={isNonEmptyString(scp?.url)}
                      callback={(e) => handleInput(e, "scp", "ipv4Addr")}
                    />
                  </div>
                  <div className="separator" style={{ marginLeft: "10%" }}></div>
                </>
              )}

              {scpisAvailable && (
                <>
                  <div className="data-row" style={{ marginLeft: "10%" }}>
                    <span>Port</span>
                    <CrossedInput
                      size="40%"
                      object={scp}
                      value={scp?.port || ""}
                      placeholder="1111"
                      clearDisabled={isNonEmptyString(scp?.url)}
                      inputDisabled={isNonEmptyString(scp?.url)}
                      callback={(e) => handleInput(e, "scp", "port")}
                    />
                  </div>
                  <div className="separator" style={{ marginLeft: "10%" }}></div>
                </>
              )}

              {scpisAvailable && (
                <>
                  <div className="data-row" style={{ marginLeft: "10%" }}>
                    <span>Url</span>

                    <OverlayTrigger overlay={<Tooltip style={{ position: "absolute" }}>Must begin by 'http://' and end by '/'</Tooltip>}>
                      <CrossedInput
                        size="40%"
                        object={scp}
                        value={scp?.url || ""}
                        placeholder="http://example.com/"
                        clearDisabled
                        inputDisabled
                        // clearDisabled={isNonEmptyString(scp?.ipv4Addr) || isNonEmptyString(scp?.port)}
                        // inputDisabled={isNonEmptyString(scp?.ipv4Addr) || isNonEmptyString(scp?.port)}
                        callback={(e) => handleInput(e, "scp", "url")}
                      />
                    </OverlayTrigger>
                  </div>
                  <div className="separator" style={{ marginLeft: "10%" }}></div>
                </>
              )}
            </Tab>

            <Tab
              eventKey="nrf-configuration"
              title={
                <div className="rowDiv j-center">
                  {alarmTable.find((el) => el.specificProblem === "NRF_NOT_REACHABLE") && (
                    <OverlayTrigger overlay={<Tooltip style={{ position: "absolute" }}>NRF_NOT_REACHABLE</Tooltip>}>
                      <span className="attention">!</span>
                    </OverlayTrigger>
                  )}
                  <span
                    style={alarmTable.find((el) => el.specificProblem === "NRF_NOT_REACHABLE") ? { borderBottom: "solid 1px red" } : {}}
                  >
                    NRF CONFIGURATION
                  </span>
                </div>
              }
            >
              <h5 className="title mt-5">NRF Configuration</h5>

              <div className="data-row">
                <span>
                  {alarmTable.find((el) => el.specificProblem === "NRF_NOT_REACHABLE") && (
                    <OverlayTrigger overlay={<Tooltip style={{ position: "absolute" }}>NRF_NOT_REACHABLE</Tooltip>}>
                      <span className="attention">!</span>
                    </OverlayTrigger>
                  )}
                  Is Available
                </span>
                <div className="rowDiv j-around">
                  <span style={{ marginTop: "8px", marginRight: "5px" }}>NO</span>
                  <Form.Check
                    style={{ marginTop: "7px" }}
                    type="switch"
                    checked={nrfisAvailable || false}
                    onChange={(e) => {
                      if (!e.target.checked) setnrf({})
                      setnrfisAvailable(e.target.checked)
                    }}
                  />
                  <span style={{ marginTop: "8px", marginLeft: "5px" }}>YES</span>
                </div>
              </div>
              <div className="separator"></div>

              {nrfisAvailable && (
                <>
                  <div className="data-row" style={{ marginLeft: "10%" }}>
                    <span>IP Address</span>
                    <CrossedInput
                      size="40%"
                      object={nrf}
                      value={nrf?.ipv4Addr || ""}
                      placeholder="192.168.1.1"
                      clearDisabled
                      inputDisabled
                      // clearDisabled={isNonEmptyString(nrf?.url)}
                      // inputDisabled={isNonEmptyString(nrf?.url)}
                      callback={(e) => handleInput(e, "nrf", "ipv4Addr")}
                    />
                  </div>
                  <div className="separator" style={{ marginLeft: "10%" }}></div>
                </>
              )}

              {nrfisAvailable && (
                <>
                  <div className="data-row" style={{ marginLeft: "10%" }}>
                    <span>Port</span>
                    <CrossedInput
                      size="40%"
                      object={nrf}
                      value={nrf?.port || ""}
                      placeholder="1111"
                      clearDisabled
                      inputDisabled
                      // clearDisabled={isNonEmptyString(nrf?.url)}
                      // inputDisabled={isNonEmptyString(nrf?.url)}
                      callback={(e) => handleInput(e, "nrf", "port")}
                    />
                  </div>
                  <div className="separator" style={{ marginLeft: "10%" }}></div>
                </>
              )}

              {nrfisAvailable && (
                <>
                  <div className="data-row" style={{ marginLeft: "10%" }}>
                    <span>Url</span>
                    <CrossedInput
                      size="40%"
                      object={nrf}
                      value={nrf?.url || ""}
                      placeholder="http://example.com/"
                      clearDisabled={isNonEmptyString(nrf?.ipv4Addr) || isNonEmptyString(nrf?.port)}
                      inputDisabled={isNonEmptyString(nrf?.ipv4Addr) || isNonEmptyString(nrf?.port)}
                      callback={(e) => handleInput(e, "nrf", "url")}
                    />
                  </div>
                  <div className="separator" style={{ marginLeft: "10%" }}></div>
                </>
              )}

              {nrfisAvailable && (
                <>
                  <div className="data-row" style={{ marginLeft: "10%" }}>
                    <span>Register</span>
                    <Button
                      disabled={nrfButtonsDisabled}
                      style={{
                        width: "40%",
                        ...(nrfButtonsDisabled && {
                          backgroundColor: "#acb0b5",
                          borderColor: "#8c8c8c",
                        }),
                      }}
                      onClick={() => register()}
                    >
                      Register
                    </Button>
                  </div>
                  <div className="separator" style={{ marginLeft: "10%" }}></div>
                </>
              )}

              {nrfisAvailable && (
                <>
                  <div className="data-row" style={{ marginLeft: "10%" }}>
                    <span>Discover</span>
                    <Button
                      style={{
                        width: "40%",
                        marginTop: "10px",
                        // ...(nrfButtonsDisabled && { backgroundColor: "#acb0b5", borderColor: "#8c8c8c" }),
                      }}
                      onClick={() => discovery()}
                    >
                      Discovery
                    </Button>
                  </div>
                  <div className="separator" style={{ marginLeft: "10%" }}></div>
                </>
              )}
            </Tab>
          </Tabs>
          <Button
            disabled={isSaveDisabled}
            style={{ width: "10%", float: "right", marginRight: "1.5%" }}
            onClick={() => checkNonMandaryData()}
          >
            Save
          </Button>
        </div>

        {/* <iframe src="https://kubeview-7d9186ff2b824df983aa164c3371d32c.5gef.net/" width="500px" height="500px"></iframe> */}
      </Form>
    </>
  )
}

export default Homepage
